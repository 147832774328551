<template>
  <div id="report-userPreference" class="report">
    <div class="vehiclePreference-vehicleBrand-userAmount">
      <div class="vehiclePreference statisticalAnalysis">
        <div class="title-filter">
          <h1>车辆偏好分析</h1>
        </div>
        <vehiclePreference class="chartDetail" />
      </div>
      <div class="vehicleBrand statisticalAnalysis">
        <div class="title-filter">
          <h1>车辆品牌分析</h1>
        </div>
        <vehicleBrand class="chartDetail" />
      </div>
      <div class="userAmount statisticalAnalysis">
        <div class="title-filter">
          <h1>用户数量分析</h1>
          <ul class="filter">
            <li>
              <div class="typeSelect">
                <span
                  v-for="item of typeList"
                  :class="{ active: type === item }"
                  @click="changeType(item)"
                  >{{ item }}</span
                >
              </div>
            </li>
          </ul>
        </div>
        <userAmount ref="userAmount" class="chartDetail" />
      </div>
    </div>
    <div class="vehicleDeviation-usageHabit">
      <div class="vehicleDeviation statisticalAnalysis">
        <div class="title-filter">
          <h1>车辆偏差分析</h1>
        </div>
        <vehicleDeviation />
      </div>
      <div class="usageHabit statisticalAnalysis">
        <div class="title-filter">
          <h1>用户使用习惯分析</h1>
        </div>
        <usageHabit class="chartDetail" />
      </div>
    </div>
    <div class="passagewayPreferences">
      <div class="statisticalAnalysis">
        <div class="title-filter">
          <h1>出入场景喜好分析</h1>
        </div>
        <passagewayPreferences ref="paymentPeriod" class="chartDetail" />
      </div>
    </div>
    <div class="paymentPreference-onlinePaymentStatistics-adClickStatistics">
      <div class="paymentPreference statisticalAnalysis">
        <div class="title-filter">
          <h1>支付偏好分析</h1>
        </div>
        <paymentPreference class="chartDetail" />
      </div>
      <div class="onlinePaymentStatistics statisticalAnalysis">
        <div class="title-filter">
          <h1>线上支付数据统计</h1>
        </div>
        <onlinePaymentStatistics class="chartDetail" />
      </div>
      <div class="adClickStatistics statisticalAnalysis">
        <div class="title-filter">
          <h1>广告点击量统计</h1>
          <ul class="filter">
            <li>
              <el-select
                  v-model="adClickStatistics.subdivision"
                  clearable
                  placeholder="选择区"
                  style="width: 120px;"
              >
                <el-option
                    v-for="item in adClickStatistics.subdivisionList"
                    :key="item"
                    :label="item"
                    :value="item"
                >
                </el-option>
              </el-select>
            </li>
            <li>
              <el-select
                v-model="adClickStatistics.parkingLotId"
                placeholder="选择停车场"
                clearable
                style="width: 120px;"
              >
                <el-option
                  v-for="item in parkingLotList"
                  :key="item.parkingLotId"
                  :label="item.parkingLotName"
                  :value="item.parkingLotId"
                >
                </el-option>
              </el-select>
            </li>
          </ul>
        </div>
        <adClickStatistics class="chartDetail" />
      </div>
    </div>
    <div class="parkingTime statisticalAnalysis">
      <div class="title-filter">
        <h1>停车时长分析</h1>
        <ul class="filter">
          <li>
            <el-select
              v-model="businessCircleId"
              clearable
              placeholder="请选择商圈"
              @change="parkingTimeChange"
            >
              <el-option
                v-for="item in businessList"
                :key="item.businessCircleId"
                :label="item.businessCircleName"
                :value="item.businessCircleId"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select
              v-model="subjectType"
              clearable
              placeholder="请选择停车场类型"
              @change="parkingTimeChange"
            >
              <el-option
                v-for="item in select_subjectType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </li>
        </ul>
      </div>
      <parkingTime ref="parkingTime" />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    vehiclePreference: () => import("./vehiclePreference"),
    vehicleBrand: () => import("./vehicleBrand"),
    vehicleDeviation: () => import("./vehicleDeviation"),
    parkingTime: () => import("./parkingTime"),
    userAmount: () => import("./userAmount"),
    passagewayPreferences: () => import("./passagewayPreferences"),
    paymentPreference: () => import("./paymentPreference"),
    onlinePaymentStatistics: () => import("./onlinePaymentStatistics"),
    adClickStatistics: () => import("./adClickStatistics"),
    usageHabit: () => import("./usageHabit"),
  },
  data() {
    return {
      businessCircleId: "",
      subjectType: "",
      typeList: ["今日", "本月", "今年"],
      type: "今日",
      adClickStatistics: {
        parkingLotId: "",
        subdivision: "",
        subdivisionList: [
          "镇海区",
          "鄞州区",
          "海曙区",
          "象山县",
          "余姚市",
          "奉化区",
          "慈溪市",
        ],
      },
    };
  },
  computed: {
    businessList() {
      return this.$store.state.select.businessList;
    },
    parkingLotList() {
      return this.$store.state.select.parkingLotList;
    },
  },
  methods: {
    parkingTimeChange() {
      this.$refs.parkingTime.getList({
        businessCircleId: this.businessCircleId ? this.businessCircleId : null,
        subjectType: this.subjectType ? this.subjectType : null,
      });
    },
    changeType(type) {
      this.type = type;
      this.$refs.userAmount.getOptions({ type: this.type });
    },
  },
};
</script>

<style lang="scss">
#report-userPreference {
  .vehiclePreference-vehicleBrand-userAmount {
    display: flex;
    justify-content: space-between;
    height: 20rem;

    .vehiclePreference {
      width: 30%;
    }

    .vehicleBrand {
      width: calc(48% - 3rem);
    }

    .userAmount {
      width: 22%;
    }
  }

  .vehicleDeviation-usageHabit {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;

    .vehicleDeviation {
      width: 60%;
      height: 365px !important;
    }

    .usageHabit {
      width: calc(40% - 1.5rem);
      height: 365px !important;
    }
  }

  .passagewayPreferences {
    height: 30rem;
    margin: 1.5rem 0;
  }

  .parkingTime {
    height: 20rem;
    margin: 1.5rem 0;
  }

  .paymentPreference-onlinePaymentStatistics-adClickStatistics {
    display: flex;
    justify-content: space-between;
    height: 20rem;

    & > div {
      width: calc(100% / 3 - 1rem);
    }
  }
}
</style>
